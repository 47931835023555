@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'comfortaa';
  src: url('./fonts/comfortaa/Comfortaa-VariableFont_wght.ttf')
    format('truetype');
}

body {
  font-family: 'comfortaa', sans-serif;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
  margin: 0 !important;
  display: flex !important;
  align-items: center !important;
}

.MuiButtonBase-root.MuiRadio-root {
  padding: 0px !important;
}

.MuiTypography-root {
  position: relative;
  bottom: 2px;
  margin-inline-start: 15px !important;
}

::selection {
  background-color: #34c87c;
  color: white;
}
