.css-1bl45wc-MuiInputBase-root-MuiFilledInput-root::before {
  border: none !important;
}
.css-1bl45wc-MuiInputBase-root-MuiFilledInput-root::after {
  border: none !important;
}
.css-batk84-MuiInputBase-root-MuiFilledInput-root::before {
  border: none !important;
}
.css-batk84-MuiInputBase-root-MuiFilledInput-root::after {
  border: none !important;
}
.css-e2jmdx::after {
  border: none !important;
}
.css-e2jmdx::before {
  border: none !important;
}
.css-13570ov::after {
  border: none !important;
}
.css-13570ov::before {
  border: none !important;
}
